<template>
  <div class="clearfix footer pt-3 px-4">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        md="4"
      >
        <div>
          <b-img
            :src="require('@/assets/images/logo/FullLogo.png')"
            width="150"
          />
        </div>
        <div class="my-1">
          <p class="text-white h4">
            ¡La mejor plataforma y comunidad virtual <br>
            donde ganas premios increíbles!
          </p>
        </div>
        <div class="mt-2">
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle mr-1"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle mr-1"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle mr-1"
          >
            <feather-icon icon="InstagramIcon" />
          </b-button>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        md="4"
      >
        <h4 class="text-white font-weight-bolder">
          SORTEOS
        </h4>
        <ol class="list-unstyled">
          <li class="my-1">
            <b-link
              class="text-white font-weight-bold"
              href=""
              target="_blank"
            >
              Nuestra Sede
            </b-link>
          </li>
          <li class="my-1">
            <b-link
              class="text-white font-weight-bold"
              href=""
              target="_blank"
            >
              ¿Quienes somos?
            </b-link>
          </li>
          <li class="my-1">
            <b-link
              class="text-white font-weight-bold"
              href=""
              target="_blank"
            >
              Contacto
            </b-link>
          </li>
        </ol>
        <div>
          <b-img
            :src="require('@/assets/images/payment_methods2.png')"
            fluid
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="4"
      >
        <h4 class="text-white font-weight-bolder mb-0">
          Boletín
        </h4>
        <div class="mt-1 mb-1">
          <b-form-input
            type="email"
            placeholder="Ingresa tu correo"
            class="border-radius newsletter"
          />
        </div>
        <b-button
          class="my-1"
          variant="success"
          pill
        >
          Suscribirme
        </b-button>
      </b-col>
    </b-row>
    <div class="d-flex align-items-center justify-content-between mt-4 pb-2">
      <span class="d-block d-md-inline-block mt-25 text-muted">
        COPYRIGHT  © {{ new Date().getFullYear() }}
        <span class="d-none d-sm-inline-block">, Todos los derechos reservados</span>
      </span>
      <div class="h6 text-white">
        Terminos y condiciones
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BImg,
  BButton,
  BLink,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BImg,
    BButton,
    BLink,
    BFormInput,
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #5014A6;
}
.border-radius {
  border-radius: 2rem;
}
.newsletter {
  background: none;
  border: 2px solid white;
  color: white;
  font-weight: 500;
  &::placeholder {
    color: white;
    font-weight: 500;
  }
}
</style>
