<template>
  <div class="pt-5 credits-content d-flex flex-column justify-content-between">
    <home-menu bgwhite />
    <b-container class="mt-1 py-5">
      <h1 class="text-center mb-3">
        Compra tus creditos
      </h1>
      <b-row>
        <b-col class="d-flex align-items-center justify-content-center">
          <b-overlay
            id="overlay-background"
            :show="loading"
            variant="light"
            opacity="0.85"
            blur="2px"
          >
            <b-button
              v-for="bundle in creditPackages"
              :key="bundle.id"
              class="credit-bundle"
              variant="primary"
              @click="buyAndSetLoading(bundle.id)"
            >
              <div class="text-center flex-column position-relative d-flex align-items-center justify-content-center">
                <div
                  class="avatar mb-1"
                >
                  <b-img
                    :src="require(`@/assets/images/logo/SmallLogo3.png`)"
                  />
                </div>
                <h2 class="mb-0 credit_ammount text-white">
                  {{ bundle.credit_amount }}
                </h2>
                <div class="h5 text-white">
                  Ticket Coins
                </div>
                <span class="times">x</span>
                <b class="text-center mt-1">
                  ${{ bundle.price | money }} USD
                </b>
              </div>
              <p class="text-center mt-1">
                {{ bundle.description }}
              </p>
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-4">
        <b-img
          src="https://www.pagadito.com/img/vw_home/pg-logo-pagadito.jpg"
          width="150"
        />
      </div>
    </b-container>
    <main-footer class="d-none d-md-block" />
  </div>
</template>

<script>
import {
  BContainer,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import MainFooter from '@/@core/components/MainFooter.vue'
import HomeMenu from '@/views/home/HomeMenu.vue'

export default {
  components: {
    MainFooter,
    HomeMenu,
    BContainer,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('credits', ['creditPackages']),
  },
  created() {
    this.getCreditPackages()
  },
  methods: {
    ...mapActions('credits', ['getCreditPackages']),
    ...mapActions('pagadito', ['buyCreditsPagadito']),
    buyAndSetLoading(id) {
      this.loading = true
      this.buyCreditsPagadito(id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.credits-content {
  min-height: 100vh;
}
.credit-bundle {
  padding: 3rem;
  border: 1px solid gray;
  width: 300px;
  margin: .5rem;
  border-radius: 20px;
  cursor: pointer;
  background: $primary;
  color: white;
}

.avatar{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease;
  background: #F6AE07;
  box-sizing: border-box;
  border: 3px solid #F7E2AB;

  img {
    width: 23px;
  }
}

.credit_ammount {
  font-weight: 900;
  font-size: 40px;

}

.times {
  font-size: 20px;
  font-weight: 600;
}
</style>
