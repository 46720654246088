<template>
  <nav
    id="menux"
    class="home-navbar"
  >
    <div class="d-flex justify-content-between">
      <router-link to="/#">
        <b-img
          :src="require('@/assets/images/logo/logo2_square.png')"
          class="logo-img"
        />
      </router-link>
      <b-navbar-nav class="nav d-flex flex-row align-items-center ml-auto">
        <div
          v-if="currentUser"
          class="d-flex flex-row align-items-center"
        >
          <b-button
            size="sm"
            class="ml-1 d-none d-md-block"
            variant="primary"
            :to="{ name: 'getCredits' }"
          >
            <em class="fas fa-coins mr-50" /> {{ credits | credits }} ticket coins
          </b-button>
          <b-button
            size="sm"
            class="ml-1 d-xs-block d-md-none"
            variant="primary"
            :to="{ name: 'getCredits' }"
          >
            <em class="fas fa-coins mr-50" /> {{ credits | credits }}
          </b-button>
          <cart-dropdown
            class="d-none d-md-block ml-1"
            :bgwhite="bgwhite"
          />
          <div class="d-sm-flex d-none user-nav">
            <p
              id="name"
              class="user-name d-none d-md-block font-weight-bolder mb-0 mr-1 ml-2 alt-color"
              :class="bgwhite ? 'text-primary':''"
            >
              {{ currentUser.name }}
            </p>
          </div>
          <user-dropdown
            v-if="currentUser.name"
            class="d-none d-md-block"
          />
        </div>
        <b-button
          v-else
          pill
          size="sm"
          class="ml-1"
          variant="success"
          :to="{ name: 'auth-login' }"
        >
          Iniciar sesión
        </b-button>
      </b-navbar-nav>
    </div>
  </nav>
</template>

<script>
import {
  BNavbarNav,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'

export default {
  components: {
    CartDropdown,
    UserDropdown,
    BNavbarNav,
    BButton,
    BImg,
  },
  props: {
    bgwhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      scrollPosition: 0,
    }
  },
  mounted() {
    /* eslint-disable */
    // this.scrollPosition = window.scrollY;
    var logoContainer = document.getElementById('menux')
    window.addEventListener('scroll', function() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 25) {
        logoContainer.classList.add('c-logo--scrolled')
        document.getElementById('name').classList.remove('alt-color')
      } else {
        logoContainer.classList.remove('c-logo--scrolled')
        document.getElementById('name').classList.add('alt-color')
      }
    })
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('credits', ['credits']),
  },
}
</script>

<style lang="scss" scoped>
.c-logo--scrolled {
  background: rgba(white, 0.9);
}

.alt-color {
  color: white;
}

.home-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  margin: 0;
  transition: all .2s ease;
  padding: 0 1rem;
}
.logo-img {
  width: 110px;
  @media screen and (max-width: 400px) {
    width: 80px;
  }
}
</style>
